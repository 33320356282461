import React, { useEffect, useState, useRef } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import TableComponent from "./TableComponent";
import { getItems } from "./functions";
import dayjs from "dayjs";
import { Modal } from "antd";
import { sprintf } from "sprintf-js";

var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

export default function Main({ role }) {
    const [items, setItems] = useState([]);
    const [tipoUsuario, setTipoUsuario]  = useState('CLIENTE');
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: { current: 1, pageSize: 50, total: 0 },
        filters: {},
        sorter: {},
    });
    const searchInput = useRef(null);
    const { confirm } = Modal;

    /* useEffect(() => {
        getList(tableParams);
    }, []); */

    useEffect(() => {
        getList(tableParams);
    }, [tipoUsuario]);

    const test = document.getElementById("test");
    test.onclick = () => {
        getList(tableParams);
    };

    const Expiration = ({ value }) => {
        if (!value) {
            return <span>-</span>;
        }

        const end_suscr = dayjs(value);
        if (end_suscr.isSame(dayjs("1001-01-01"))) {
            return (
                <span className="text-primary fw-bold">
                    <i className="fa-solid fa-caret-right me-1"></i>
                    INDEFINIDO
                </span>
            );
        }

        if (dayjs().isSameOrBefore(end_suscr)) {
            return (
                <span className="text-success fw-bold">
                    <i className="fa-solid fa-caret-up me-1"></i>
                    {end_suscr.format("DD/MM/YYYY")}
                </span>
            );
        } else {
            return (
                <span className="text-danger fw-bold">
                    <i className="fa-solid fa-caret-down me-1"></i>
                    {end_suscr.format("DD/MM/YYYY")}
                </span>
            );
        }
    };

    const getList = async (params) => {
        try {
            setLoading(true);
            let { data } = await getItems({
                ...params,
                 filters: {
                    ...params.filters,
                    tipo: [ tipoUsuario ]
                 }
            });
            let { rows, total, current } = data;
            setItems(rows);
            setTableParams((prev) => {
                return {
                    ...prev,
                    pagination: {
                        ...prev.pagination,
                        total: total,
                        current: current,
                    },
                };
            });
        } catch (error) {
            throw new Error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        setTableParams((prev) => {
            return { ...prev, ...params };
        });
        getList(params);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div className="p-1" onKeyDown={(e) => e.stopPropagation()}>
                <input
                    type="text"
                    className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder="Escribe algo..."
                    value={selectedKeys[0] ? selectedKeys[0] : ""}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            handleSearch(selectedKeys, confirm, dataIndex);
                        }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button
                        className="btn btn-sm btn-light border mx-1"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button
                        className="btn btn-sm btn-light border mx-1"
                        onClick={() =>
                            clearFilters &&
                            handleReset(clearFilters, setSelectedKeys, confirm)
                        }
                    >
                        Reset
                    </button>

                    <button
                        className="btn btn-sm btn-light border mx-1"
                        onClick={() => {
                            close();
                        }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const confirmDelete = (id) => {
        confirm({
            title: "Confirmación",
            content: "¿Eliminar?",
            onOk: () => {
                livewire.emitTo("usuarios.form", "del_item", id);
            },
        });
    };

    const OptionsColumn = ({ item }) => {
        return (
            <div>
                {
                    (["ADMIN", "EDITOR"].includes(role))? (
                        <button
                            className="btn btn-outline-secondary py-0 px-1 me-1"
                            onClick={() => {
                                livewire.emitTo("usuarios.form", "set_item", item.id);
                            }}
                        >
                            <span className="fas fa-pen"></span>
                        </button>
                    ):(<></>)
                }

                {
                    (role === "ADMIN")? (
                        <button
                            className="btn btn-outline-secondary py-0 px-1"
                            onClick={() => confirmDelete(item.id)}
                        >
                            <span className="fas fa-trash"></span>
                        </button>
                    ) : (<></>)
                }
            </div>
        );
    };

    const columns = [
        {
            title: "*",
            key: "*",
            width: (role === "VISUALIZADOR")? 0 : (role === "EDITOR")? 40: 100,
            render: (value, row) => <OptionsColumn item={row} />,
        },
        { title: "ID", dataIndex: "id", key: "id", width: 80, sorter: true,
            align: "center",
            render: (value) => (<>{ sprintf("%04d", value) }</>)
        },
        {
            title: "NOMBRE",
            dataIndex: "nombre",
            key: "nombre",
            width: 200,
            sorter: true,
            render: (value, row) => {
                return row.tipo === "CLIENTE" ? (
                    <a href={`/usuarios/${row.id}/suscripciones`}>{value}</a>
                ) : (
                    <span>{value}</span>
                );
            },
            filterDropdown: getColumnSearchProps("nombre").filterDropdown,
            onFilterDropdownOpenChange:
                getColumnSearchProps("nombre").onFilterDropdownOpenChange,
        },
        {
            title: "TIPO",
            dataIndex: "tipo",
            key: "tipo",
            width: 150,
            sorter: true,
        },
        {
            title: "EMAIL",
            dataIndex: "email",
            key: "email",
            width: 240,
            sorter: true,
            filterDropdown: getColumnSearchProps("email").filterDropdown,
            onFilterDropdownOpenChange:
                getColumnSearchProps("email").onFilterDropdownOpenChange,
        },
        {
            title: "CELULAR",
            dataIndex: "celular",
            key: "celular",
            width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps("email").filterDropdown,
            onFilterDropdownOpenChange:
                getColumnSearchProps("email").onFilterDropdownOpenChange,
        },
        {
            title: "FECHA EXP",
            dataIndex: "fecha_exp",
            key: "fecha_exp",
            width: 120,
            sorter: true,
            render: (value, row) => (
                <Expiration value={row.max_suscription?.end_suscription} />
            ),
        },
        {
            title: "REGISTRO",
            dataIndex: "obs",
            key: "obs",
            width: 120,
            render: (value, row) => (value ? <>PRUEBA</> : <>INTERNO</>),
        },
        {
            title: "ACTIVO",
            dataIndex: "activo",
            key: "activo",
            width: 100,
            sorter: true,
            render: (value) =>
                value ? (
                    <span>
                        <span className="fas fa-circle-dot pe-1 text-success"></span>
                        Activo
                    </span>
                ) : (
                    <span>
                        <span className="fas fa-circle-dot pe-1 text-danger"></span>
                        Inactivo
                    </span>
                ),
        },
        {
            title: "RUBRO",
            dataIndex: "u_rubro",
            key: "u_rubro",
            width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps("u_rubro").filterDropdown,
            onFilterDropdownOpenChange:
                getColumnSearchProps("u_rubro").onFilterDropdownOpenChange,
        },
        {
            title: "SUBRUBRO",
            dataIndex: "u_subrubro",
            key: "u_subrubro",
            width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps("u_subrubro").filterDropdown,
            onFilterDropdownOpenChange:
                getColumnSearchProps("u_subrubro").onFilterDropdownOpenChange,
        },
        {
            title: "PAÍS",
            dataIndex: "u_pais",
            key: "u_pais",
            width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps("u_pais").filterDropdown,
            onFilterDropdownOpenChange:
                getColumnSearchProps("u_pais").onFilterDropdownOpenChange,
        },
        {
            title: "CIUDAD",
            dataIndex: "u_ciudad",
            key: "u_ciudad",
            width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps("u_ciudad").filterDropdown,
            onFilterDropdownOpenChange:
                getColumnSearchProps("u_ciudad").onFilterDropdownOpenChange,
        },
        {
            title: "RESPONSABLE",
            dataIndex: "responsable",
            key: "responsable",
            width: 200,
            sorter: true,
            filterDropdown: getColumnSearchProps("responsable").filterDropdown,
            onFilterDropdownOpenChange:
                getColumnSearchProps("responsable").onFilterDropdownOpenChange,
            render: (value) => (<>{ (value)? value.nombre : "N/D" }</>)
        },
    ];

    const hancleChangeTipoUsuario = (e) => {
        setTipoUsuario(e.target.value);
    }

    return (
        <div>
            <div className="mb-3 row">
                <div className="col-md-5">
                    <div className="d-flex">
                        <div className="input-group" style={{width: 'auto'}}>
                            {
                                (["ADMIN","EDITOR"].includes(role))? (
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-light border me-2"
                                        onClick={() => { livewire.emitTo('usuarios.form', 'set_item'); }}
                                    >
                                        <span className="fas fa-plus-circle pe-1"></span>
                                        Nuevo
                                    </button>
                                ) : (<></>)
                            }
                        </div>
                        <div className="d-flex flex-row">
                            <span className="pt-1 ms-4" style={{ width: '110px'}}>Mostrar por:</span>
                            <select name="tipo_usuario" id="tipo_usuario"
                                className="form-select form-select-sm"
                                style={{ width: '150px' }}
                                value={tipoUsuario}
                                onChange={hancleChangeTipoUsuario}
                            >
                                {
                                    (role === "ADMIN")? (
                                        <>
                                            <option value="ADMIN">Administrador</option>
                                            <option value="EDITOR">Editor</option>
                                            <option value="VISUALIZADOR">Visualizador</option>
                                            <option value="CLIENTE">Cliente</option>
                                        </>
                                    ) : (
                                        <>
                                            <option value="CLIENTE">Cliente</option>
                                        </>
                                    )
                                }
                            </select>
                        </div>

                    </div>
                </div>
                <div className="col-md text-end">
                    {
                        (["ADMIN","EDITOR"].includes(role))? (
                            <a
                                href="/csv"
                                className="btn btn-sm btn-light border"
                                target="_blank"
                            >
                                <i className="fa fa-table me-1"></i>
                                Descargar CSV
                            </a>
                        ): (<></>)
                    }
                </div>
            </div>
            <TableComponent
                items={items}
                isLoading={loading}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                columns={columns}
                scroll={{ y: 400 }}
            />
        </div>
    );
}

let main = document.getElementById("usuarios-main");

if (main) {
    let role = main.dataset.userRole;
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBoundary>
                <Main role={role}/>
            </ErrorBoundary>
        </React.StrictMode>
    );
}
