import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { Collapse } from "antd";

const Main = ({ info, videos, viewed }) => {
    const [currentVideo, setCurrentVideo] = useState(null);
    const videoTag = useRef();

    const info_decoded = atob(info);
    const info_data = JSON.parse(info_decoded);

    const [vistos, setVistos] = useState([...viewed]);

    useEffect(() => {
        videoTag.current.play();
    }, [currentVideo]);

    const IconShowed = ({ code = "" }) => {
        if (vistos.includes(code)) {
            return <span className="fas fa-check-circle text-success"></span>;
        } else {
            return <span className="fas fa-caret-right text-secondary"></span>;
        }
    };

    const playVideo = (video) => {
        setCurrentVideo(video);
    };

    const sendInfo = async (code) => {
        try {
            let res = await axios.post("/api/save-video-info", {
                platform: "cat",
                user_id: info_data.user_id,
                code: code,
            });
            return true;
        } catch (e) {
            return false;
        }
    };

    const HandleEnded = async () => {
        let sended = await sendInfo(currentVideo.code);
        if (!sended) {
            return;
        }

        if (!vistos.includes(currentVideo.code)) {
            setVistos((prev) => [...prev, currentVideo.code]);
        }

        const index = videos.findIndex((vid) => vid.code === currentVideo.code);
        if (index < videos.length - 1) {
            playVideo(videos[index + 1]);
        }
    };

    const ItemsVideo = ({ videos }) => {
        return (
            <>
                {videos.map((vid) => (
                    <li key={vid.code} className="list-group-item">
                        <div className="d-flex flex-row">
                            <div className="text-center px-2">
                                <IconShowed code={vid.code} />
                            </div>
                            <div className="flex-grow-1">
                                <button
                                    className="py-0 btn-video"
                                    style={
                                        vid.code === currentVideo?.code
                                            ? { background: "#757474" }
                                            : {}
                                    }
                                    onClick={() => playVideo(vid)}
                                >
                                    {vid.title}
                                </button>
                            </div>
                        </div>
                    </li>
                ))}
            </>
        );
    };

    return (
        <div className="">
            <div className="row gx-1">
                <div className="col-4">
                    <div
                        className="d-flex flex-column"
                        style={{
                            height: "100lvh",
                            backgroundColor: "#3c3c3c",
                            color: "white",
                        }}
                    >
                        <span className="fw-bold p-2">Tutoriales</span>
                        <div
                            className="flex-grow-1"
                            style={{ overflowY: "scroll" }}
                        >
                            <Collapse
                                className="custom-collapse"
                                items={Object.keys(videos).map((key) => ({
                                    key: videos[key].title,
                                    label: videos[key].title,
                                    children: (
                                        <ul className="list-group">
                                            <ItemsVideo
                                                videos={videos[key].videos}
                                            />
                                        </ul>
                                    ),
                                }))}
                            ></Collapse>

                        </div>
                        <div className="my-4">
                            <div className="text-center">
                                Potenciado por
                            </div>
                            <div className="sidebar-footer"></div>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    <video
                        className="w-100"
                        style={{ height: "100lvh" }}
                        ref={videoTag}
                        width="320"
                        height="240"
                        src={
                            currentVideo
                                ? `/TUTORIALES/${currentVideo.path}`
                                : null
                        }
                        onEnded={HandleEnded}
                        controls
                    ></video>
                </div>
            </div>
        </div>
    );
};

let main = document.getElementById("tutoriales-main");

if (main) {
    const info = main.dataset.info;
    const videos = JSON.parse(main.dataset.videos);
    const viewed = JSON.parse(main.dataset.viewed);

    const root = createRoot(main);
    root.render(<Main info={info} videos={videos} viewed={viewed} />);
}
